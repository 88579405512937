

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: grey;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #212529;
  height: 45px;
}

.dialog-container {
  display: flex;
  height: 70vh;
}

.dialog-window {
  display: none;
  /* border: 1px solid #ccc; */
  padding: 20px;
  background-color: white;
  width: 100%;

}

.input-record {

  width: 100%;
  font-size: 12px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid grey;
}

.input-record-date {
  width: 10vh;
  font-size: 12px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid grey;
}

.database-btn { 
  padding-left: 2px;
  padding-top: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
  font-size: 10px;
}

.database-btn:hover{
  background-color: #0D6EFD;
  color: white;
}

.asc-button{
  height: 28px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #212529;
  color: white;
  border-radius: 10px;
}

.desc-button {
  height: 28px;
  background-color: #212529;
  color: white;
  border-radius: 10px;
}

.asc-button:hover {
  background-color: #0D6EFD;
}

.desc-button:hover {
  background-color: #0D6EFD;
}



.record-map {
  height: 55%;
  width: 100%;
}

.record-map-div {
  height: 35vh;
}

.pagination-button {
  color: white;
  background-color: #212529;
  font-size: 12px;
  padding: 5px;
  margin-left: 2px;
  margin-top: 2px;
  border-radius: 10px;
}

.pagination-button:hover{
  background-color: #0D6EFD;
}

.next-button {
  color: white;
  background-color: #212529;
  font-size: 12px;
  padding: 5px;
  margin-left: 2px;
  margin-top: 2px;
  border-radius: 10px;
}

.next-button:hover{
  background-color: #0D6EFD;
}



.hr-line {
  background: #f9f9f9;
  color: #f9f9f9;
  border-color: #f9f9f9;
  height: 0.5px;
  margin-top: 0px;
  width:100%;
}

.hr-line-2 {
  background: #f9f9f9;
  color: #f9f9f9;
  border-color: #f9f9f9;
  height: 0.5px;
  margin-top: 10px;
  width: 100%;
}


.dialog-window.active {
  display: block;
}

.record-button {
  color: rgb(8,102,255);
  border: none;
  margin-right: 20px;
  font-size: 14px;
  background-color: white;
}

.record-button:hover{
  color: black; 
}

.record-button.active{
  color: black;
  border-top: 1px solid lightslategrey;
  border-left: 1px solid lightslategrey;
  border-right:  1px solid lightslategrey;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.people-button {
  border: none;
  background-color: white;
  font-size: 20px;
  color: black;
}

.parties-button{
  border: none;
  background-color: white;
  font-size: 20px;
  color: black;
}

.parties-button:hover{
  background-color: #f2f2f2;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}


.photos-button {
  border: none;
  background-color: white;
  font-size: 20px;
  color: black;
}

.photos-button:hover {
  background-color: #f2f2f2;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.people-button:hover {
  background-color: #f2f2f2;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.vehicle-button {
  border: none;
  background-color: white;
  font-size: 20px;
  color: black;
}

.child-button{
  border: none;
  background-color: white;
  font-size: 20px;
  color: black;
}

.child-button:hover{
  background-color: #f2f2f2;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.vehicle-button:hover {
  background-color: #f2f2f2;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}





.logo {
  text-decoration: none;
  font-size: 20px;
  color: inherit;
  margin-right: 20px;
  color: white;
  background-color: #212529;
  border: none;
  border-radius: 5px;
}

/* menu on desktop */
.desktop-nav .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.desktop-nav .menu-items {
  position: relative;
  font-size: 14px;
}

.desktop-nav .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.desktop-nav button span {
  margin-left: 3px;
}

.desktop-nav .menu-items > a,
.desktop-nav .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.desktop-nav .menu-items a:hover,
.desktop-nav .menu-items button:hover {
  color: white;
}

.desktop-nav .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.desktop-nav .dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #212529;
  border-radius: 0.5rem;
  display: none;
}

.desktop-nav .dropdown.show {
  display: block;
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.mobile-nav {
  display: none;
}


.loc-select {
  font-size: 14px;
  background-color: #212529;
  border: none;
  color: grey;
  border-color: #212529;
  margin-left: 10px;

}

.loc-select:hover{
  background-color: #212529;
  color: white;
  border: none;
  border-color: #212529;
}

.loc-select_1 {
  font-size: 14px;
  background-color: #212529;
  border: none;
  color: grey;
  border-color: #212529;
  margin-left: 10px;


}

.button_privacy{
  background-color: #0D6EFD;
  width: 100px;
  height: 30px;
  font-size: 15px;
  color: white;
  float: right;
  margin-right: 30%;
  border-radius: 5px;
  border: none;
  
}

.button_privacy:hover {
  background-color: #212529;
}

.loc-select_1:hover{
  background-color: #212529;
  color: white;
  border: none;
  border-color: #212529;
}


.loc-option{
  background-color: #212529;
  border: none;
}



.loc-option:hover{
  border: none;
  background-color: #212529;
  color: white;
}

.input-image {
  border: 1px solid grey;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}


.loc-option_1{
  background-color: #212529;
  border: none;
  margin-top: 10px;
  width: 100px;
}

.loc-option1:hover{
  border: none;
  background-color: #212529;
  color: white;
}

/* menu on mobile */
@media screen and (max-width: 960px) {
  .nav-area {
    justify-content: space-between;
  }

  .desktop-nav {
    display: none;
    text-align: right;
  }

  .mobile-nav {
    display: block;
    height: 45px;
    /* position:fixed; */
  }


  .mobile-nav .mobile-nav__menu-button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    font-size: 35px;
    margin-top: 7px;
    float: left;
  }
  .mobile-nav .menus {
    list-style: none;
    position: fixed;
    top: 40px;
    right: 0;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: #212529;
    transition: right 0.4s;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    float: none;
  }



  .mobile-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .mobile-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .mobile-nav .menu-items > a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }

  .mobile-nav .menu-items a:hover,
  .mobile-nav .menu-items button:hover {
    background-color: #f2f2f2;
  }

  .mobile-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  .mobile-nav .arrow-close::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-bottom: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .mobile-nav .dropdown.show {
    display: block;
  }

.record-button{
  font-size: 12px;
}

.people-button{
  font-size: 12px;
}

.photos-button{
  font-size: 12px;
}

.vehicle-button {
  font-size: 12px;
}

.dialog-container {
 font-size: 11px;
 padding: 0;
}

.input-record {
  width: 30vh;
}

.input-record1 {
  width: 30vh;
}

.record-map {
  width: 100%;
  
}

.hr-line-2 {
  display: none;
}

.input-image {
  width: 30vh;
}




}

.responsive-box {
  overflow-y: auto; /* Allows scrolling in mobile view */
}

/* Smaller screen adjustments */
@media (max-width: 600px) {
  .responsive-box {
    padding: 8px !important; /* Less padding on smaller screens */
  }

  .MuiDialog-paper {
    width: 100% !important; /* Ensure the dialog takes full width on mobile */
  }

  .MuiTabs-flexContainer {
    flex-wrap: wrap; /* Wrap tabs if necessary */
  }
}

/* page content */
.content {
  max-width: 100%;
}

.content h1 {
  font-size: 2rem;
}

#error-page {
  /* center content on the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}

.filter-box {
  background-color: #212529;
  width: 35px;
  font-size: 19px;
  border: 1px solid grey;
  color: grey;
  border-radius: 3px;
  margin-left: 5px;
  margin-right: 10px;
}

.filter-box:hover {
  background-color: grey;
  color: white;
}

.record-box {
  background-color: #212529;
  width: 35px;
  font-size: 19px;
  border: 1px solid grey;
  color: grey;
  border-radius: 3px;
}

.report-box {
  background-color: #212529;
  width: 35px;
  font-size: 19px;
  border: 1px solid grey;
  color: grey;
  border-radius: 3px;
  margin-left: 10px;
}

.record-box:hover {
  background-color: grey;
  color: white;
}

.report-box:hover {
  background-color: grey;
  color: white;
}

.date-time-button {
  color: blue;
  border: none;
  background-color: white;
  font-size: 12px;
}

.date-time-button:hover {
  color: navy;
}

.privacy-box{
  font-size: 14px;
  background-color: #212529;
  color: grey;
  border: 0px solid grey;
  margin-left: 18px;
}

.privacy-box:hover {
  color: white;
}

.stph-box{
  font-size: 14px;
  background-color: #212529;
  color: grey;
  border: 0px solid grey;
  margin-left: 15px;
  
}

.stph-box:hover{
  color: white;
}

.site-title1 {
  font-size: 30px;
  margin-top: -7px;
  margin-left: -15px;
  color: white;
  
}

.list-table{
  background-color: #0D6EFD;
}


.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.yes-btn, .no-btn {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.yes-btn {
  background-color: green;
  color: white;
}

.no-btn {
  background-color: red;
  color: white;
}